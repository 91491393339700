import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import NumberFormat from "react-number-format";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { locale, addLocale } from 'primereact/api';

// funcoes proprias
// import BarraInferior from '../componentes/BarraInferior';
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import Api from '../Api';

function Receber() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const [ listagem, setListagem ] = useState([]);
    const [ totais, setTotais ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [ showModalFiltros, setShowModalFiltros ] = useState(false); //para os filtros.
    const [ email, setEmail ] = useState('');
    const [ enviando, setEnviando ] = useState('');
    const [ filtros, setFiltros ] = useState({
        dataInicial: '',
        dataFinal: '',
        status: 'TODOS',
        pesquisa: ''
    });
    const selectStatus = [
        {value: "TODOS", label: "TODOS" },
        {value: "A", label: "ABERTOS" },
        {value: "B", label: "BAIXADOS" },
        {value: "C", label: "CANCELADOS" },
        {value: "P", label: "PRE BAIXADOS" },
    ];

    function listarReceber(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;
            //setPagina((paginaInsideState) => paginaInsideState+1);
                        
            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('pesquisa', filtros.pesquisa);
            formData.set('datainicial', new Date(filtros.dataInicial).toLocaleDateString()); //a string em filtros está assim: 2024-12-10T03:00:00.000Z e desta forma pegamos apenas a data neste formado: 10/12/2024
            formData.set('datafinal', new Date(filtros.dataFinal).toLocaleDateString());
            formData.set('status',filtros.status);
                       
            Api.post('receber.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg.listagem); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    if (novaPagina===1) {
                        setTotais(response.data.msg.totais);
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 40) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarReceber(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    function carregarFiltros() {
        const hoje = new Date();
        let dataInicial = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
        let dataFinal = new Date(hoje.getFullYear(), hoje.getMonth() +1, 0);
        let status = 'TODOS';
        let pesquisa = '';
        const filtros_receber = JSON.parse(localStorage.getItem('filtros_receber'));
        if (filtros_receber) {
            dataInicial = new Date(filtros_receber.dataInicial);
            dataFinal = new Date(filtros_receber.dataFinal);
            status = filtros_receber.status;
            pesquisa = filtros_receber.pesquisa;
        } else {
            localStorage.setItem('filtros_receber',JSON.stringify({dataInicial, dataFinal, status, pesquisa}));
        }
        setFiltros({ dataInicial, dataFinal, status, pesquisa });
    }
    
    function resetarFiltros() {
        const hoje = new Date();
        const dataInicial = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
        const dataFinal = new Date(hoje.getFullYear(), hoje.getMonth() +1, 0);
        const status = 'TODOS';
        const pesquisa = '';
        setFiltros({ dataInicial, dataFinal, status, pesquisa });
        localStorage.setItem('filtros_receber',JSON.stringify({ dataInicial, dataFinal, status, pesquisa }));
    }

    useEffect(() => {
        carregarFiltros();
        locale('pt');
        addLocale('pt', {
            "startsWith":"Começa com",
            "contains":"Contém",
            "notContains":"Não contém",
            "endsWith":"Termina com",
            "equals":"Igual",
            "notEquals":"Diferente",
            "noFilter":"Sem filtro",
            "filter": "Filtro",
            "lt":"Menor que",
            "lte":"Menor que ou igual a",
            "gt":"Maior que",
            "gte":"Maior que ou igual a",
            "dateIs":"Data é",
            "dateIsNot":"Data não é",
            "dateBefore":"Date é anterior",
            "dateAfter":"Data é posterior",
            "custom":"Customizado",
            "clear":"Limpar",
            "close":"Fechar",
            "apply":"Aplicar",
            "matchAll":"Match All",
            "matchAny":"Match Any",
            "addRule":"Adicionar Regra",
            "removeRule":"Remover Regra",    
            "accept":"Sim",
            "reject":"Não",
            "choose":"Escolha",
            "upload":"Upload",
            "cancel":"Cancelar",
            "completed": "Concluído",
            "pending": "Pendente",
            "fileSizeTypes": ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            "dayNames": ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
            "dayNamesShort": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            "dayNamesMin": ["D", "S", "T", "Q", "Q", "S", "S"],
            "monthNames": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            "monthNamesShort": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            "chooseYear": "Escolha Ano",
            "chooseMonth": "Escolha Mês",
            "chooseDate": "Escolha Data",
            "prevDecade": "Década Anterior",
            "nextDecade": "Década Seguinte",
            "prevYear": "Ano Anterior",
            "nextYear": "Ano Seguinte",
            "prevMonth": "Mês Anterior",
            "nextMonth": "Mês Seguinte",
            "prevHour": "Hora Anterior",
            "nextHour": "Hora Seguinte",
            "prevMinute": "Minuto Anterior",
            "nextMinute": "Minuto Seguinte",
            "prevSecond": "Segundo Anterior",
            "nextSecond": "Segundo Seguinte",
            "am": "am",
            "pm": "pm",
            "today": "Hoje",
            "now": "Agora",
            "weekHeader": "Sem",
            "firstDayOfWeek": 0,
            "showMonthAfterYear": false,
            "dateFormat": "dd/mm/yy",
            "weak": "Fraco",
            "medium": "Médio",
            "strong": "Forte",
            "passwordPrompt": "Digite uma senha",
            "emptyFilterMessage":"Nenhum resultado encontrado",
            "searchMessage": "{0} resultados disponíveis",
            "selectionMessage": "{0} itens selecionados",
            "emptySelectionMessage": "Nenhum item selecionado",
            "emptySearchMessage": "Nenhum resultado encontrado",
            "emptyMessage":"Nenhuma opção disponível",
            "aria": {
                "trueLabel": "Verdadeiro",
                "falseLabel": "Falso",
                "nullLabel": "Não selecionado",
                "star": "1 estrela",
                "stars": "{star} estrelas",
                "selectAll": "Todos itens selecionados",
                "unselectAll": "Nenhum item selecionado",
                "close": "Fechar",
                "previous": "Anterior",
                "next": "Seguinte",
                "navigation": "Navegação",
                "scrollTop": "Rolar para Topo",
                "moveTop": "Mover para Topo",
                "moveUp": "Mover para Cima",
                "moveDown": "Mover para Baixo",
                "moveBottom": "Mover para Final",
                "moveToTarget": "Mover para Alvo",
                "moveToSource": "Mover para Fonte",
                "moveAllToTarget": "Mover Todos para Alvo",
                "moveAllToSource": "Mover Todos para Fonte",
                "pageLabel": "Página {page}",
                "firstPageLabel": "Primeira Página",
                "lastPageLabel": "Última Página",
                "nextPageLabel": "Página Seguinte",
                "previousPageLabel": "Página Anterior",
                "rowsPerPageLabel": "Linhas por página",
                "jumpToPageDropdownLabel": "Pular para Menu da Página",
                "jumpToPageInputLabel": "Pular para Campo da Página",
                "selectRow": "Linha Selecionada",
                "unselectRow": "Linha Não Selecionada",
                "expandRow": "Linha Expandida",
                "collapseRow": "Linha Recolhida",
                "showFilterMenu": "Mostrar Menu de Filtro",
                "hideFilterMenu": "Esconder Menu de Filtro",
                "filterOperator": "Operador de Filtro",
                "filterConstraint": "Restrição de Filtro",
                "editRow": "Editar Linha",
                "saveEdit": "Salvar Editar",
                "cancelEdit": "Cancelar Editar",
                "listView": "Exibição em Lista",
                "gridView": "Exibição em Grade",
                "slide": "Deslizar",
                "slideNumber": "{slideNumber}",
                "zoomImage": "Ampliar Imagem",
                "zoomIn": "Mais Zoom",
                "zoomOut": "Menos Zoom",
                "rotateRight": "Girar à Direita",
                "rotateLeft": "Girar à Esquerda"
            }
        });
        listarReceber(0,false,true);      
        // eslint-disable-next-line
    },[]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarReceber(0,false,true);
        setShowModalFiltros(false);

        //salva a pesquisa no local storage.
        localStorage.setItem('filtros_receber', JSON.stringify(filtros));
    };
    
    // async function btnSalvar() {
    //     const formData = new FormData();
    //     formData.set('op','inserir');
    //     formData.set('CLI_BAIRRO',receber.CLI_BAIRRO || '');
    //     formData.set('CLI_CEP',receber.CLI_CEP || '');
    //     formData.set('CLI_CIDADE',receber.CLI_CIDADE || '');
    //     const CLI_USUARIO = usuarioLogin;
    //     const CLI_DTATUALIZACAO = moment().format('DD/MM/YYYY');
    //     setReceber({...receber, CLI_DTATUALIZACAO, CLI_USUARIO});
    //     const response = await Api.post('clientes.php',formData);
    //     if (response.data.erro==='N') {
    //         Swal.fire({title: 'Yessss!', text: 'Cliente cadastrado com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
    //         setShowModal(false);
    //         navigate('/cliente/'+response.data.msg.CLI_CODIGO);
    //     } 
    //     if (response.data.erro==='S') {
    //         Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
    //     }
    // }    

    // const setCampo = (e) => {
    //     const { name, value } = e.target;
    //     let valor = value?value.toUpperCase():'';
    //     if (name==='CLI_EMAIL') {
    //         valor = valor.toLowerCase();
    //     }
    //     setReceber({...receber, [name]: valor});
    // }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="filtros") {
            setFiltros({...filtros, [name]: valor});
        }
    }

    const localizarKeyDown = (e) => {
        if (e.keyCode === 13) {
            Pesquisar();
        }
    }  

    async function enviarListagem() {
        setEnviando(true);
        const formData = new FormData();
        formData.set('op','enviarpdf');
        formData.set('pesquisa', filtros.pesquisa);
        formData.set('datainicial', new Date(filtros.dataInicial).toLocaleDateString()); //a string em filtros está assim: 2024-12-10T03:00:00.000Z e desta forma pegamos apenas a data neste formado: 10/12/2024
        formData.set('datafinal', new Date(filtros.dataFinal).toLocaleDateString());
        formData.set('status', filtros.status);
        formData.set('email', email);
        try {
            const response = await Api.post('receber.php',formData);
            if (response.data.erro==='N') {
                Swal.fire({title: 'Yessss!', text: response.data.msg, icon: 'success', showConfirmButton: false, timer: 1500});
            }
            if (response.data.erro==='S') {
                response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
            }
        } catch (error) {
            console.log('CATCH',error);
        }
        setEmail('');
        setEnviando(false);
        setShowModal(false);
    }

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">CONTAS A RECEBER</p></div>
                        <div className="link-icone me-3"><Link to='' onClick={() => setShowModal(true)}><i className="fa fa-2x fa-fw fa-share"></i></Link></div>
                        <div className="link-icone"><Link to='' onClick={() => setShowModalFiltros(true)}><i className="fa fa-2x fa-fw fa-sliders-h"></i></Link></div>
                    </div>
                </div>
            </div>
        </div>

        <div className="fixed-bottom bg-dark p-1">
            <div className="container-fluid limit-width" style={{fontSize: '11px'}}>
                <div className="row g-1">
                    <div className="col-2 text-light text-end">ABERTO&nbsp;</div>
                    <div className="col-4 text-light text-end rounded bg-danger">
                        <NumberFormat 
                            value={parseFloat(totais.ABERTOS)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>
                    <div className="col-2 text-light text-end">PRÉ B.&nbsp;</div>
                    <div className="col-4 text-light text-end rounded bg-primary">
                        <NumberFormat 
                            value={parseFloat(totais.PREBAIXADOS)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>

                
                    <div className="col-2 text-light text-end">BAIXADO&nbsp;</div>
                    <div className="col-4 text-light text-end rounded bg-success">
                        <NumberFormat 
                            value={parseFloat(totais.BAIXADOS)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>
                    <div className="col-2 text-light text-end">TOTAL&nbsp;</div>
                    <div className="col-4 text-black text-end rounded bg-warning">
                        <NumberFormat 
                            value={parseFloat(totais.TOTAL)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>                  

                    <div className="col-2 text-light text-end">CANCEL.&nbsp;</div>
                    <div className="col-4 text-light text-end rounded bg-secondary">
                        <NumberFormat 
                            value={parseFloat(totais.CANCELADOS)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>
                    <div className="col-2 text-light text-end">PAGO&nbsp;</div>
                    <div className="col-4 text-black text-end rounded bg-light">
                        <NumberFormat 
                            value={parseFloat(totais.TOTALPAGO)}
                            // isNumericString={true}
                            displayType="text"
                            prefix="R$ "
                            thousandsGroupStyle="thousand"
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator="."
                            allowNegative={true}
                        />
                    </div>

                </div>
            </div>
        </div>

        <IfComponent condicional={ listagem.length > 0}>
        <div className="container limit-width p-0 topspace-50" style={{marginBottom: '90px'}}>
            {                    
                listagem.map(receber => (
                    <div className="card rounded-0 border-0 border-bottom w-100" key={receber.REE_CODIGO}>
                        <div className="card-body text-truncate p-1">
                            {/* <div className="col-12 mx-2 lh-1 pt-2 text-truncate"> */}
                                    <Link to={"/receber/"+receber.REE_CODIGO} className="link-preto stretched-link">
                                        <span className="fw-bold">{receber.REE_CLIENTE} - {receber.CLI_NOME}</span>
                                    </Link>
                            {/* </div> */}
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                    <span className="small">{receber.REE_CODIGO}</span>
                                    <span className="text-muted small">{receber.REE_DTVENCIMENTO}</span>
                                    <span className="text-danger fw-bold small me-1">
                                        <NumberFormat 
                                            value={parseFloat(receber.REE_VALOR)}
                                            // isNumericString={true}
                                            displayType="text"
                                            prefix="R$ "
                                            thousandsGroupStyle="thousand"
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator="."
                                            allowNegative={true}
                                        />&nbsp;
                                        <IfComponent condicional={receber.REE_STATUS==="A"}>
                                            <span className="text-light badge bg-danger">{receber.REE_STATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={receber.REE_STATUS==="B"}>
                                            <span className="text-light badge bg-success">{receber.REE_STATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={receber.REE_STATUS==="C"}>
                                            <span className="badge bg-secondary">{receber.REE_STATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={receber.REE_STATUS==="P"}>
                                            <span className="text-light badge bg-primary">{receber.REE_STATUS}</span>
                                        </IfComponent>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }   
        </div>

        </IfComponent>           
        <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-sack-dollar"></i></span><br/>Nenhuma conta a receber encontrada!<br/>
                <Button className="btn btn-primary mt-3" onClick={() => setShowModalFiltros(true)}><i className="fa fa-fw fa-sliders-h"></i> Verificar Filtros</Button></p>
            </div>                    
        </IfComponent>

        <Modal show={showModalFiltros} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container-fluid limit-width">
                    <Modal.Title><h5 className="modal-title">FILTROS</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-2">
                    <div className="row g-0">

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Dropdown className="w-100" name="status" value={filtros.status || ''} options={selectStatus} onChange={(e) => setCampo(e,'filtros',false)}/>
                                <label htmlFor="status">STATUS</label>
                            </span>
                        </div>

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Calendar className="w-100" name="dataInicial" inputId="dataInicial" value={new Date(filtros.dataInicial)} onChange={(e) => setCampo(e,'filtros',false)} dateFormat="dd/mm/yy" locale='pt' showButtonBar autoZIndex />
                                <label htmlFor="dataInicial">DATA INICIAL</label>
                            </span>
                        </div>
                        
                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Calendar className="w-100" name="dataFinal" inputId="dataFinal" value={new Date(filtros.dataFinal)} onChange={(e) => setCampo(e,'filtros',false)} dateFormat="dd/mm/yy" locale='pt' showButtonBar autoZIndex />
                                <label htmlFor="dataFinal">DATA FINAL</label>
                            </span>
                        </div>

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <InputText className="w-100" name="pesquisa" value={filtros.pesquisa} onChange={(e) => setCampo(e,'filtros',true)} onKeyDown={(e) => localizarKeyDown(e)}/>
                                <label htmlFor="pesquisa">LOCALIZAR</label>
                            </span>
                        </div>
                                                
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark limit-height text-light p-0 d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button className="" variant="danger" onClick={() => setShowModalFiltros(false)}>Cancelar</Button>
                        <Button className="" variant="warning" onClick={() => resetarFiltros(false)}>Resetar</Button>
                        <Button className="" variant="success" onClick={() => Pesquisar()}><i className="fa fa-fw fa-check"></i> Filtrar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light py-0 limit-height">
                <div className="container limit-width">
                    <Modal.Title><h5 className="modal-title">Enviar Contas a Receber</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width">
                    <div className="row mb-0 mt-5 gx-2 p-inputtext-sm pett-input">
                        <div className="col-12 mb-2">
                            <span className="p-float-label pett-label">
                                <InputText className="w-100" name="EMAIL" value={email || ''} onChange={(e) => setEmail(e.target.value)} required/>
                                <label htmlFor="EMAIL">EMAIL DO DESTINATARIO</label>
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark text-light p-0 limit-height d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                        {
                            enviando
                                ? <Button variant="success disabled"><i className="fa fa-fw fa-spin fa-spinner"></i> Enviando...</Button>
                                : <Button variant="success" onClick={() => enviarListagem()}><i className="fa fa-fw fa-paper-plane"></i> Enviar</Button>
                        }
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    </>
}

export default Receber;