import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import NumberFormat from "react-number-format";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Button as ButtonP } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { locale, addLocale } from 'primereact/api';

// funcoes proprias
import Api from '../Api';
import { AuthContext } from '../contextos/Auth';
import IfComponent from '../componentes/IfComponent';
import ClientesLoc from '../componentes/ClientesLoc';
import CondicoesLoc from '../componentes/CondicoesLoc';

function Vendas() {
    const { logout } = useContext(AuthContext);
    const scrollObserver = useRef();
    const navigate = useNavigate();
    const [ listagem, setListagem ] = useState([]);
    const [ carregando, setCarregando ] = useState(false);
    const [ pagina, setPagina ] = useState(0);
    const [ final, setFinal ] = useState(false);
    const [ scrollRatio, setScrollRatio ] = useState(null);
    const [ venda, setVenda ] = useState({'VED_CLIENTE': '', 'VED_NOME':''});
    
    const [ showModal, setShowModal ] = useState(false); //para novo pedido.
    const [ showModalFiltros, setShowModalFiltros ] = useState(false); //para os filtros.
    const [ showClientesLoc, setShowClientesLoc ] = useState(false);
    const [ showCondicoesLoc, setShowCondicoesLoc ] = useState(false);

    const [ filtros, setFiltros ] = useState({
        dataInicial: '',
        dataFinal: '',
        status: 'TODOS',
        viaInternet: 'TODOS',
        pesquisa: ''
    });
    
    const selectStatus = [
        {value: "TODOS", label: "TODOS" },
        {value: "A", label: "ABERTOS" },
        {value: "F", label: "FINALIZADOS" },
        {value: "C", label: "CANCELADOS" },
        {value: "I", label: "INUTILIZADOS" },
    ];

    const selectViaInternet = [
        {value: "TODOS", label: "TODOS" },
        {value: "A", label: "SIM - ABERTOS" },
        {value: "F", label: "SIM - FINALIZADOS" },
        {value: "N", label: "NÃO" },
    ];

    function listarVendas(nPagina,bFinal,bLimpar) {
        if (!bFinal) {
            setCarregando(true);    
            const novaPagina=nPagina+1;

            var formData = new FormData();
            formData.set('op', 'listar');
            formData.set('pagina', novaPagina);
            formData.set('pesquisa', filtros.pesquisa);
            // formData.set('datainicial', filtros.dataInicial.toLocaleDateString());
            formData.set('datainicial', new Date(filtros.dataInicial).toLocaleDateString()); //a string em filtros está assim: 2024-12-10T03:00:00.000Z e desta forma pegamos apenas a data neste formado: 10/12/2024
            formData.set('datafinal', new Date(filtros.dataFinal).toLocaleDateString());
            formData.set('status', filtros.status);
            formData.set('viainternet', filtros.viaInternet);
                       
            Api.post('vendas.php',formData).then((response) => {
                if (response.data.erro==='N') {
                    const novaListagem = [];
                    if (response.data.contador > 0) {
                        if (!bLimpar){
                            //const novaListagem = [...listagem] //novalistagem recebe listagem atual.
                            novaListagem.push(...listagem); //novalistagem recebe listagem atual.
                            setListagem([]);
                        }
                        novaListagem.push(...response.data.msg); //novalistagem recebe mais a nova listagem.
                    } else {
                        //se ja tem uma listagem, continua com ela
                        //se a pesquisa retornou 20 registros, ao dar scroll para a pagina 2 que retorna com 0 registros, continua com a listagem atual.
                        if (novaPagina > 1) { 
                            novaListagem.push(...listagem); 
                        }
                    }

                    //verifica se pode conter mais registros. 
                    //se retornou com 20 registros, pode ser que ainda tenha mais.
                    //se retornou com menos de 20 registros, é pq chegou no final da lista.
                    if (response.data.contador < 20) {
                        setFinal(true);
                    }

                    setListagem(novaListagem);
                    setCarregando(false);
                    setPagina(novaPagina);
                } else {
                    response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
                }
            }).catch(({response}) => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
            const ratio = entries[0].intersectionRatio;
            setScrollRatio(ratio);
        });
        
        intersectionObserver.observe(scrollObserver.current);
        return () => {
            intersectionObserver.disconnect();
        }
    },[]);

    //executa este bloco toda vez que o div sentinela aparece na tela.
    useEffect(() => {      
        if (scrollRatio > 0) {
            listarVendas(pagina,final,false); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scrollRatio]);

    
    function carregarFiltros() {
        const hoje = new Date();
        let dataInicial = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
        let dataFinal = new Date(hoje.getFullYear(), hoje.getMonth() +1, 0);
        let status = 'TODOS';
        let viaInternet = 'TODOS';
        let pesquisa = '';
        const filtros_vendas = JSON.parse(localStorage.getItem('filtros_vendas'));
        if (filtros_vendas) {
            dataInicial = new Date(filtros_vendas.dataInicial);
            dataFinal = new Date(filtros_vendas.dataFinal);
            status = filtros_vendas.status;
            viaInternet = filtros_vendas.viaInternet;
            pesquisa = filtros_vendas.pesquisa;
        } else {
            localStorage.setItem('filtros_vendas',JSON.stringify({dataInicial, dataFinal, status, viaInternet, pesquisa}));
        }
        setFiltros({ dataInicial, dataFinal, status, viaInternet, pesquisa });
    }
    
    function resetarFiltros() {
        const hoje = new Date();
        const dataInicial = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
        const dataFinal = new Date(hoje.getFullYear(), hoje.getMonth() +1, 0);
        const status = 'TODOS';
        const viaInternet = 'TODOS';
        const pesquisa = '';
        setFiltros({ dataInicial, dataFinal, status, viaInternet, pesquisa });
        localStorage.setItem('filtros_vendas',JSON.stringify({ dataInicial, dataFinal, status, viaInternet, pesquisa }));
    }

    useEffect(() => {
        carregarFiltros();
        locale('pt');
        addLocale('pt', {
            "startsWith":"Começa com",
            "contains":"Contém",
            "notContains":"Não contém",
            "endsWith":"Termina com",
            "equals":"Igual",
            "notEquals":"Diferente",
            "noFilter":"Sem filtro",
            "filter": "Filtro",
            "lt":"Menor que",
            "lte":"Menor que ou igual a",
            "gt":"Maior que",
            "gte":"Maior que ou igual a",
            "dateIs":"Data é",
            "dateIsNot":"Data não é",
            "dateBefore":"Date é anterior",
            "dateAfter":"Data é posterior",
            "custom":"Customizado",
            "clear":"Limpar",
            "close":"Fechar",
            "apply":"Aplicar",
            "matchAll":"Match All",
            "matchAny":"Match Any",
            "addRule":"Adicionar Regra",
            "removeRule":"Remover Regra",    
            "accept":"Sim",
            "reject":"Não",
            "choose":"Escolha",
            "upload":"Upload",
            "cancel":"Cancelar",
            "completed": "Concluído",
            "pending": "Pendente",
            "fileSizeTypes": ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            "dayNames": ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
            "dayNamesShort": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            "dayNamesMin": ["D", "S", "T", "Q", "Q", "S", "S"],
            "monthNames": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            "monthNamesShort": ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            "chooseYear": "Escolha Ano",
            "chooseMonth": "Escolha Mês",
            "chooseDate": "Escolha Data",
            "prevDecade": "Década Anterior",
            "nextDecade": "Década Seguinte",
            "prevYear": "Ano Anterior",
            "nextYear": "Ano Seguinte",
            "prevMonth": "Mês Anterior",
            "nextMonth": "Mês Seguinte",
            "prevHour": "Hora Anterior",
            "nextHour": "Hora Seguinte",
            "prevMinute": "Minuto Anterior",
            "nextMinute": "Minuto Seguinte",
            "prevSecond": "Segundo Anterior",
            "nextSecond": "Segundo Seguinte",
            "am": "am",
            "pm": "pm",
            "today": "Hoje",
            "now": "Agora",
            "weekHeader": "Sem",
            "firstDayOfWeek": 0,
            "showMonthAfterYear": false,
            "dateFormat": "dd/mm/yy",
            "weak": "Fraco",
            "medium": "Médio",
            "strong": "Forte",
            "passwordPrompt": "Digite uma senha",
            "emptyFilterMessage":"Nenhum resultado encontrado",
            "searchMessage": "{0} resultados disponíveis",
            "selectionMessage": "{0} itens selecionados",
            "emptySelectionMessage": "Nenhum item selecionado",
            "emptySearchMessage": "Nenhum resultado encontrado",
            "emptyMessage":"Nenhuma opção disponível",
            "aria": {
                "trueLabel": "Verdadeiro",
                "falseLabel": "Falso",
                "nullLabel": "Não selecionado",
                "star": "1 estrela",
                "stars": "{star} estrelas",
                "selectAll": "Todos itens selecionados",
                "unselectAll": "Nenhum item selecionado",
                "close": "Fechar",
                "previous": "Anterior",
                "next": "Seguinte",
                "navigation": "Navegação",
                "scrollTop": "Rolar para Topo",
                "moveTop": "Mover para Topo",
                "moveUp": "Mover para Cima",
                "moveDown": "Mover para Baixo",
                "moveBottom": "Mover para Final",
                "moveToTarget": "Mover para Alvo",
                "moveToSource": "Mover para Fonte",
                "moveAllToTarget": "Mover Todos para Alvo",
                "moveAllToSource": "Mover Todos para Fonte",
                "pageLabel": "Página {page}",
                "firstPageLabel": "Primeira Página",
                "lastPageLabel": "Última Página",
                "nextPageLabel": "Página Seguinte",
                "previousPageLabel": "Página Anterior",
                "rowsPerPageLabel": "Linhas por página",
                "jumpToPageDropdownLabel": "Pular para Menu da Página",
                "jumpToPageInputLabel": "Pular para Campo da Página",
                "selectRow": "Linha Selecionada",
                "unselectRow": "Linha Não Selecionada",
                "expandRow": "Linha Expandida",
                "collapseRow": "Linha Recolhida",
                "showFilterMenu": "Mostrar Menu de Filtro",
                "hideFilterMenu": "Esconder Menu de Filtro",
                "filterOperator": "Operador de Filtro",
                "filterConstraint": "Restrição de Filtro",
                "editRow": "Editar Linha",
                "saveEdit": "Salvar Editar",
                "cancelEdit": "Cancelar Editar",
                "listView": "Exibição em Lista",
                "gridView": "Exibição em Grade",
                "slide": "Deslizar",
                "slideNumber": "{slideNumber}",
                "zoomImage": "Ampliar Imagem",
                "zoomIn": "Mais Zoom",
                "zoomOut": "Menos Zoom",
                "rotateRight": "Girar à Direita",
                "rotateLeft": "Girar à Esquerda"
            }
        });
        listarVendas(0,false,true);      
        // eslint-disable-next-line
    },[]);

    function Pesquisar() {
        setPagina(0);
        setFinal(false);
        //setListagem([]);      
        listarVendas(0,false,true);
        setShowModalFiltros(false);

        //salva a pesquisa no local storage.
        localStorage.setItem('filtros_vendas', JSON.stringify(filtros));
    };
    
    async function btnSalvar() {
        const formData = new FormData();
        formData.set('op','inserirvenda');
        formData.set('VED_CLIENTE',venda.VED_CLIENTE || '');
        formData.set('VED_CONDICAO',venda.VED_CONDICAO || '');
        formData.set('VED_OBSERVACOES',venda.VED_OBSERVACOES || '');
        const response = await Api.post('vendas.php',formData);
        if (response.data.erro==='N') {
            Swal.fire({title: 'Yessss!', text: 'Pedido gerado com sucesso!', icon: 'success', showConfirmButton: false, timer: 1500});
            setShowModal(false);
            navigate('/venda/'+response.data.msg.VED_CODIGO);
        } 
        if (response.data.erro==='S') {
            response.data.token==='expirado'?logout():Swal.fire({title: 'Ah nãooooo!', text: response.data.msg, icon: 'error', confirmButtonText: 'OK', confirmButtonColor: '#212529'});
        }
    }    

    const setCampoBlob = (e) => {
        const { name, value } = e.target;
        //let valor = value?value.toUpperCase():'';
        setVenda({...venda, [name]: value});
    }

    const setCampo = (e,objeto,upper) => {
        const { name, value } = e.target;
        let valor='';
        if (upper) {
            valor = value?value.toUpperCase():'';
        } else {
            valor = value;
        }
        if (objeto==="filtros") {
            setFiltros({...filtros, [name]: valor});
        }
    }
    
    //funcoes do modal de clientes.
    function ClientesLocClose(event, data) {
        setVenda({...venda, 'VED_CLIENTE': data.CLI_CODIGO, 'VED_NOME': data.CLI_NOME })
        setShowClientesLoc(false);
    }

    function ClientesLocOpen(event) {
        setShowClientesLoc(true);
    }

    //funcoes do modal de condicoes.
    function CondicoesLocClose(event, data) {
        setVenda({...venda, 'VED_CONDICAO': data.CPG_CODIGO, 'CPG_DESCRICAO': data.CPG_DESCRICAO })
        setShowCondicoesLoc(false);
    }

    function CondicoesLocOpen(event) {
        setShowCondicoesLoc(true);
    }

    const localizarKeyDown = (e) => {
        if (e.keyCode === 13) {
            Pesquisar();
        }
    } 

    return <>
        <div className="bg-dark fixed-top">
            <div className="container-fluid limit-width">
                <div className="row">
                    <div className="col-12 limit-height d-flex justify-content-between align-items-center">
                        <div className="link-icone"><Link to='/home'><i className="fa fa-2x fa-fw fa-chevron-left"></i></Link></div>
                        <div className="flex-grow-1"><p className="text-truncate text-center text-light fw-bolder mt-3">PEDIDOS DE VENDAS</p></div>
                        <div className="link-icone me-3"><Link to='' onClick={() => setShowModal(true)}><i className="fa fa-2x fa-fw fa-plus"></i></Link></div>
                        <div className="link-icone"><Link to='' onClick={() => setShowModalFiltros(true)}><i className="fa fa-2x fa-fw fa-sliders-h"></i></Link></div>
                    </div>
                </div>
            </div>
        </div>

        <IfComponent condicional={ listagem.length > 0}>
        <div className="container limit-width p-0 topspace-50">
            {                    
                listagem.map(venda => (
                    <div className="card rounded-0 border-0 border-bottom w-100" key={venda.VED_CODIGO}>
                        <div className="row">
                            <div className="col-12 px-3 pt-1 text-truncate">
                                <Link to={"/venda/"+venda.VED_CODIGO} className="link-preto stretched-link">
                                    <span className="fw-bold">{venda.VED_CLIENTE} - {venda.VED_NOME}</span>
                                </Link>
                            </div>
                            <div className="row">                               
                                <div className="col-12 mx-2 pb-2 lh-1 d-flex justify-content-between align-items-center">
                                    <span className="small">{venda.VED_CODIGO}</span>
                                    <span className="text-muted small">{venda.VED_DTEMISSAO}</span>
                                    <span className="text-danger fw-bold small">
                                        <NumberFormat 
                                            value={parseFloat(venda.VED_VALORTOTAL)}
                                            // isNumericString={true}
                                            displayType="text"
                                            prefix="R$ "
                                            thousandsGroupStyle="thousand"
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator="."
                                            allowNegative={true}
                                        />&nbsp;
                                        <IfComponent condicional={venda.VED_STATUS==="A"}>
                                            <IfComponent condicional={venda.VED_VIAINTERNETSTATUS==='A'}>
                                                <span className="text-light badge bg-info">{venda.VED_STATUS+'-'+venda.VED_VIAINTERNETSTATUS}</span>
                                            </IfComponent>
                                            <IfComponent condicional={venda.VED_VIAINTERNETSTATUS==='F'}>
                                                <span className="text-light badge bg-primary">{venda.VED_STATUS+'-'+venda.VED_VIAINTERNETSTATUS}</span>
                                            </IfComponent>
                                        </IfComponent>
                                        <IfComponent condicional={venda.VED_STATUS==="F"}>
                                            <span className="text-light badge bg-success">{venda.VED_STATUS+'-'+venda.VED_VIAINTERNETSTATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={venda.VED_STATUS==="C"}>
                                            <span className="badge bg-danger">{venda.VED_STATUS+'-'+venda.VED_VIAINTERNETSTATUS}</span>
                                        </IfComponent>
                                        <IfComponent condicional={venda.VED_STATUS==="I"}>
                                            <span className="text-light badge bg-warning">{venda.VED_STATUS+'-'+venda.VED_VIAINTERNETSTATUS}</span>
                                        </IfComponent>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }   
        </div>
        </IfComponent>           
        <div className="container limit-width bg-transparent pb-1" ref={scrollObserver} id="sentinela"></div>
        <IfComponent condicional={ carregando && !final }>
            <div className="container limit-width pb-5 mb-5 text-center"><i className="fa fa-fw fa-spin fa-spinner"></i> Carregando mais...</div>
        </IfComponent>
        <IfComponent condicional={ listagem.length === 0 && carregando === false }>
            <div className="container limit-width vh-100 d-flex justify-content-center align-items-center">
                <p className="text-center lead"><span style={{fontSize: '70px'}} className="text-muted"><i className="fa fa-fw fa-shopping-cart"></i></span><br/>Nenhum pedido de venda encontrado!<br/>
                <Button className="btn btn-primary mt-3" onClick={() => setShowModalFiltros(true)}><i className="fa fa-fw fa-sliders-h"></i> Verificar Filtros</Button></p>
            </div>                    
        </IfComponent>

        <Modal show={showModalFiltros} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container-fluid limit-width">
                    <Modal.Title><h5 className="modal-title">FILTROS</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-2">
                    <div className="row g-0">

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Dropdown className="w-100" name="status" value={filtros.status || ''} options={selectStatus} onChange={(e) => setCampo(e,'filtros',false)}/>
                                <label htmlFor="status">STATUS</label>
                            </span>
                        </div>

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Dropdown className="w-100" name="viaInternet" value={filtros.viaInternet || ''} options={selectViaInternet} onChange={(e) => setCampo(e,'filtros',false)}/>
                                <label htmlFor="viaInternet">VIA INTERNET</label>
                            </span>
                        </div>

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Calendar className="w-100" name="dataInicial" inputId="dataInicial" value={new Date(filtros.dataInicial)} onChange={(e) => setCampo(e,'filtros',false)} dateFormat="dd/mm/yy" locale='pt' showButtonBar autoZIndex />
                                <label htmlFor="dataInicial">DATA INICIAL</label>
                            </span>
                        </div>
                        
                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <Calendar className="w-100" name="dataFinal" inputId="dataFinal" value={new Date(filtros.dataFinal)} onChange={(e) => setCampo(e,'filtros',false)} dateFormat="dd/mm/yy" locale='pt' showButtonBar autoZIndex />
                                <label htmlFor="dataFinal">DATA FINAL</label>
                            </span>
                        </div>

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label mb-1">
                                <InputText className="w-100" name="pesquisa" value={filtros.pesquisa} onChange={(e) => setCampo(e,'filtros',true)} onKeyDown={(e) => localizarKeyDown(e)}/>
                                <label htmlFor="pesquisa">LOCALIZAR</label>
                            </span>
                        </div>
                                                
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark limit-height text-light p-0 d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button className="" variant="danger" onClick={() => setShowModalFiltros(false)}>Cancelar</Button>
                        <Button className="" variant="warning" onClick={() => resetarFiltros(false)}>Resetar</Button>
                        <Button className="" variant="success" onClick={() => Pesquisar()}><i className="fa fa-fw fa-check"></i> Filtrar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>

        <Modal show={showModal} fullscreen={true} backdrop="static">
            <Modal.Header className="bg-dark text-light p-0 limit-height">
                <div className="container-fluid limit-width">
                    <Modal.Title><h5 className="modal-title">NOVO PEDIDO</h5></Modal.Title>
                </div>
            </Modal.Header>

            <Modal.Body className="p-0">
                <div className="container limit-width mt-2">
                    <div className="row g-0">

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText className="" name="VED_CLIENTE" value={venda.VED_CLIENTE} readOnly/>
                                    <label htmlFor="VED_CLIENTE">CLIENTE</label>
                                </span>
                                <InputText className="w-75" name="VED_NOME" value={venda.VED_NOME} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => ClientesLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 mb-1 p-inputtext-sm pett-input">
                            <div className="p-inputgroup">
                                <span className="p-float-label pett-label w-25">
                                    <InputText className="" name="VED_CONDICAO" value={venda.VED_CONDICAO || ''} readOnly/>
                                    <label htmlFor="VED_CONDICAO">CONDIÇÃO</label>
                                </span>
                                <InputText className="w-75" name="CPG_DESCRICAO" value={venda.CPG_DESCRICAO || ''} readOnly/>
                                <ButtonP className="px-3 p-button-success" icon="pi pi-search" onClick={(e) => CondicoesLocOpen(e)}/>
                            </div>          
                        </div>

                        <div className="col-12 p-inputtext-sm pett-input">
                            <span className="p-float-label pett-label">
                                <InputTextarea className="w-100" name="VED_OBSERVACOES" value={venda.VED_OBSERVACOES || ''} onChange={(e) => setCampoBlob(e)} autoResize />
                                <label htmlFor="VED_OBSERVACOES">OBSERVAÇÕES</label>
                            </span>
                        </div>

                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className='bg-dark limit-height text-light p-0 d-flex justify-content-center'>
                <div className="container limit-width p-0">
                    <div className="btn-group w-100">
                        <Button className="" variant="danger" onClick={() => setShowModal(false)}>Cancelar</Button>
                        <Button className="" variant="success" onClick={() => btnSalvar()}><i className="fa fa-fw fa-check"></i> Salvar</Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
        <ClientesLoc showModal={showClientesLoc} onCloseModal={ClientesLocClose}/>
        <CondicoesLoc showModal={showCondicoesLoc} onCloseModal={CondicoesLocClose}/>
    </>
}

export default Vendas;